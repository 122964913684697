<template src="./Base.html"></template>


<script>

import { mapGetters } from "vuex";
import {ACCOUNTING_TYPE, AFISHA_WORK_MODE, TICKET_LEVEL} from "@/core/common";



export default {

  name: "Settings",


  data: () => ({

    work_mode: '',


  }),

  created() {

    //console.log();

  },

  computed: {
    TICKET_LEVEL() {
      return TICKET_LEVEL
    },

    ...mapGetters([
      "getSettings",
      "getSettingsWorkMode",
      "getTicket",
      "getTicketBase"
    ]),

    getTicketLevel(){

      //TOTAL_BAD
      let res = {
        bg: "amber lighten-4",
        header: "red darken-2",
        text: "black--text"
      }

      switch (this.getTicket.level){

        case TICKET_LEVEL.TOTAL_BAD:
          res = {
            bg: "amber lighten-4",
            header: "red darken-2",
            text: "black--text"
          }
          break;

        case TICKET_LEVEL.EMULATE_GOOD:
          res = {
            bg: "grey lighten-3",
            header: "blue darken-2",
            text: "black--text"
          }
          break;

        case TICKET_LEVEL.LOCAL_BASE_GOOD:
          res = {
            bg: "grey darken-3",
            header: "green darken-2",
            text: "white--text"
          }
          break;

        case TICKET_LEVEL.INET_GOOD:
          res = {
            bg: "green lighten-4",
            header: "green darken-2",
            text: "black--text"
          }
          break;


      }

      return res;

    }
  },



  methods: {

    getPushkaText(isVisited){
      return isVisited? "ПОГАШЕН":"ПОГАСИТЬ";
    },

    onClickClearBase(){

      this.$store.commit('deleteLocalTicketScanBase');

    },

    onClickCheckPushka(ticket){

      console.log(ticket);

      this.$store.dispatch('getTicketByRID', {rid: ticket.pushka.rid, id: ticket.id});

    },

    onClickVisitPushka(ticket){

      console.log(ticket);

      this.$store.dispatch('setVisitPushkaTicketByRID', {rid: ticket.pushka.rid, id: ticket.id});

    },


    getBaseTicketColors(ticket){

      console.log(ticket);

      let r = {
        body: "#b800cc",
        body_text: "#000",

        header: "#ff9c9c",
        header_text: "#000",

        type: "NA"
      };

      switch (ticket.info.type){

        case ACCOUNTING_TYPE.INET:
          r.type = "Интернет";
          break;

        case ACCOUNTING_TYPE.KASSA:
          r.type = "Касса";
          break;

        case ACCOUNTING_TYPE.MONGER:
          r.type = "Уполномоченный";
          break;
      }


      if (ticket.flag.isError){

        r.body = '#ffd1d1';

        if (ticket.info.scan_mode===AFISHA_WORK_MODE.EVENT){

          if (ticket.flag.isEmulateGood){
            r.body = '#e0e0e0';
            r.header = '#a5a5a6';
          }

          if (ticket.flag.isEmulateOneEventGood){
            r.body = '#c7d6ff';
            r.header = '#7a9dff';
          }
        }
      }
      else {

        if (ticket.info.scan_mode===AFISHA_WORK_MODE.AFISHA){

          if (ticket.flag.isEmulateGood){
            r.header = '#abef00';
            r.body = '#1d5800';
          }
        }

        if (ticket.info.scan_mode===AFISHA_WORK_MODE.EVENT){

          if (ticket.flag.isLocalBaseGood){
            r.header = '#bbf825';
            r.body = '#e6ffc1';

            if (ticket.flag.isPushka){
              r.header = '#ffa1f8';
              r.body = '#e6ffc1';
            }

            if (!ticket.flag.isOriginal){
              r.header = '#ffb767';
              r.body = '#ffcb93';

              if (ticket.flag.isPushka){
                r.header = '#ffa1f8';
              }
            }
          }
        }

      }

      return r;

    },



    onScanClick(){

      //let line = "Театр Самарт ждет вас в гости! IM 464--3668-25622"
      //let line = "Театр Самарт ждет вас в гости! IM 489--11375-24344"
      let line = "Театр Самарт ждет вас в гости! IM 489--11353-24702"


      this.$store.dispatch('doScanNew', line);

    }




  }

}


</script>

<style scoped>


</style>

